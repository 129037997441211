<template lang="pug">
#page-blocked
  error-page(empty-description="Доступ запрещен")
    template(#errorMessage) Ваш аккаунт был заблокирован.
    template(#errorDescription v-if="!isCompanyBlocked") Обратитесь к администратору Вашей компании.

</template>

<script lang="ts">
import { defineComponent } from "vue";
import ErrorPage from "@/components/pages/error/ErrorPage.vue";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

export default defineComponent({
  name: "PageBlocked",
  components: {
    ErrorPage,
  },
  setup() {

    const { enabled } = useUserAccess()
    const isCompanyBlocked = computed(() => enabled.value?.includes(useRuntimeConfig().public.ROLE_COMPANY_BLOCKED))

    return {
      isCompanyBlocked,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#page-blocked {
  @include adaptive-view;
}
</style>
